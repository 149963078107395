import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";

import LoginLayout from "src/layouts/LoginLayout";
import LogIn from "src/pages/Authentication/LogIn";
import Register from "src/pages/Authentication/Register";
import ForgotPassword from "src/pages/Authentication/ForgotPassword";
import ResetPassword from "src/pages/Authentication/ResetPassword";

import PrivateRouter from "./PrivateRouter";

import { history } from "src/store";
import {
  authStatusSelector,
  isLoggedInSelector,
  userSelector,
} from "src/store/system/selector";
import { getAuthToken } from "src/config";
import { fetchUser, logOut } from "src/store/system/actions";
import { loadDashboardData } from "src/store/dashboard/actions";
import { AppLoader } from "src/components";
import {
  getAccountsLoading,
  getAccountsLoaded,
} from "src/store/account/selector";
import {
  getProfileLoaded,
  getProfileLoading,
} from "src/store/profileBuild/selector";
import { AUTH_STATUS } from "src/interfaces";

const Router = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const authStatus = useSelector(authStatusSelector);

  const user = useSelector(userSelector);
  const accountsLoading = useSelector(getAccountsLoading);
  const accountsLoaded = useSelector(getAccountsLoaded);
  const profileLoading = useSelector(getProfileLoading);
  const profileLoaded = useSelector(getProfileLoaded);

  const isSetUser = user && user.first_name + user.last_name;

  const initialLoading =
    (profileLoading.household && !profileLoaded.household) ||
    (accountsLoading && !accountsLoaded) ||
    (profileLoading.progress && !profileLoaded.progress);
  const authorized = authStatus === AUTH_STATUS.SUCCEED;
  const unAuthorized = authStatus === AUTH_STATUS.FAILED;
  const authorizing = authStatus === AUTH_STATUS.LOADING;

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      dispatch(fetchUser());
    } else {
      dispatch(logOut());
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (
      authorized &&
      isSetUser &&
      !initialLoading &&
      !profileLoaded.household
    ) {
      dispatch(loadDashboardData());
    }
  }, [user, authStatus]);

  if ((authorized && initialLoading) || authorizing) {
    return <AppLoader />;
  }
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/login" exact>
          <LoginLayout pageTitle="Welcome Back!">
            <LogIn />
          </LoginLayout>
        </Route>
        <Route path="/register/curriculum" exact>
          <LoginLayout pageTitle="Create Your Account">
            <Register curriculum />
          </LoginLayout>
        </Route>
        <Route path="/register" exact>
          <LoginLayout pageTitle="Create Your Account">
            <Register />
          </LoginLayout>
        </Route>
        <Route path="/forgot-password" exact>
          <LoginLayout pageTitle="Reset Your Password">
            <ForgotPassword />
          </LoginLayout>
        </Route>
        <Route path="/reset-password" exact>
          <LoginLayout pageTitle="Reset Your Password">
            <ResetPassword />
          </LoginLayout>
        </Route>
        {authorized && <PrivateRouter />}
        {(unAuthorized || !isLoggedIn) && <Redirect to="/login" />}
      </Switch>
    </ConnectedRouter>
  );
};

export default Router;
