import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  FormLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import {
  getIDRVsPrepayTableApi,
  IDRVsPrepayTableResponse,
} from "src/apiService";
import Button from "src/components/Button";
import { STATES } from "src/constants";
import { getIsMarried } from "src/store/system/selector";
import { DollarTextField, PercentTextField } from "src/utils";
import IDRTable from "./IDRTable";

interface PayoffVsIDRFormProps {
  formValues: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  submit: (tabIndex?: number) => void;
  goBack: VoidFunction;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    padding: 20,
    margin: "0 auto",
    maxWidth: 800,
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  inputField: {
    minWidth: 120,
  },
  groupHeader: {},
});

export const PayoffVsIDRForm = ({
  formValues,
  onChange,
  submit,
  goBack,
}: PayoffVsIDRFormProps) => {
  const styles = useStyles();
  const isMarried = useSelector(getIsMarried);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [
    tableResults,
    setTableResults,
  ] = useState<IDRVsPrepayTableResponse | null>(null);

  const goToStepTwo = () => {
    setLoading(true);
    const payload = {
      idrcalc_tax_filing_status: isMarried
        ? formValues.idrcalc_tax_filing_status || 2
        : 1,
      idrcalc_household_size: formValues.idrcalc_household_size || 1,
      idrcalc_graduate_loans: formValues.idrcalc_graduate_loans || "n",
      idrcalc_agi: formValues.idrcalc_agi || 0,
      idrcalc_spouse_agi: formValues.idrcalc_spouse_agi || 0,
      idrcalc_agi_ann_incr: formValues.idrcalc_agi_ann_incr || 0,
      idrcalc_state: formValues.idrcalc_state || "TX",
      idrcalc_pslf: formValues.idrcalc_pslf || "n",
    };
    getIDRVsPrepayTableApi(payload).then((result) => {
      setLoading(false);
      setTableResults(result);
      setStep(2);
    });
  };

  const goToStepOne = () => setStep(1);

  if (step === 1) {
    return (
      <Paper className={styles.root}>
        {isMarried && (
          <Box className={styles.inputRow}>
            <FormLabel>What is your tax filing status?</FormLabel>
            <Select
              variant="outlined"
              name="idrcalc_tax_filing_status"
              value={formValues.idrcalc_tax_filing_status || ""}
              onChange={onChange}
              className={styles.inputField}
            >
              <MenuItem value={2}>Married filing jointly</MenuItem>
              <MenuItem value={3}>Married filing separately</MenuItem>
            </Select>
          </Box>
        )}
        <Box className={styles.inputRow}>
          <FormLabel>What is your household size?</FormLabel>
          <TextField
            variant="outlined"
            type="number"
            name="idrcalc_household_size"
            value={formValues.idrcalc_household_size || ""}
            onChange={onChange}
            className={styles.inputField}
          />
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>Were any of your loans for graduate school?</FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_graduate_loans"
            value={formValues.idrcalc_graduate_loans || ""}
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
          </Select>
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>What is your annual gross income/AGI?</FormLabel>
          <DollarTextField
            variant="outlined"
            name="idrcalc_agi"
            value={formValues.idrcalc_agi || ""}
            onChange={onChange}
            className={styles.inputField}
          />
        </Box>
        {isMarried && (
          <Box className={styles.inputRow}>
            <FormLabel>
              What is your spouse’s annual gross income/AGI?
            </FormLabel>
            <DollarTextField
              variant="outlined"
              name="idrcalc_spouse_agi"
              value={formValues.idrcalc_spouse_agi || ""}
              onChange={onChange}
              className={styles.inputField}
            />
          </Box>
        )}
        <Box className={styles.inputRow}>
          <FormLabel>
            What long-term annual rate of increase in income do you expect?
          </FormLabel>
          <PercentTextField
            variant="outlined"
            name="idrcalc_agi_ann_incr"
            value={formValues.idrcalc_agi_ann_incr || ""}
            onChange={onChange}
            className={styles.inputField}
          />
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>Which state do you live in?</FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_state"
            value={formValues.idrcalc_state || ""}
            onChange={onChange}
            className={styles.inputField}
          >
            {STATES.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>
            Are you pursuing Public Service Loan Forgiveness?
          </FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_pslf"
            value={formValues.idrcalc_pslf || ""}
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
          </Select>
        </Box>
        <Button
          className="my-3"
          fbColor="primary"
          onClick={goToStepTwo}
          fullWidth
        >
          Next
        </Button>
        <Button fbColor="secondary" onClick={goBack} fullWidth>
          Back
        </Button>
      </Paper>
    );
  }
  return (
    <>
      <IDRTable loading={loading} data={tableResults} />
      <Paper className={styles.root}>
        <Typography variant="h6" className={styles.groupHeader}>
          For Your Pay Off Strategy (Strategy 1):
        </Typography>
        <Box className={styles.inputRow}>
          <FormLabel>
            Do you want to extend your Federal loans to 25 years?
          </FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_federal_extended"
            value={formValues.idrcalc_federal_extended || ""}
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={"y"}>Yes</MenuItem>
            <MenuItem value={"n"}>No</MenuItem>
          </Select>
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>Would you be making monthly prepayments?</FormLabel>
          <DollarTextField
            variant="outlined"
            name="idrcalc_mthly_pmt"
            value={formValues.idrcalc_mthly_pmt || ""}
            onChange={onChange}
          />
        </Box>
        <Typography variant="h6" className={styles.groupHeader}>
          For Your Federal IDR Strategy (Strategy 2):
        </Typography>
        <Box className={styles.inputRow}>
          <FormLabel>Select a Federal IDR Plan:</FormLabel>
          <Select
            variant="outlined"
            name="idrcalc_select_plan"
            value={
              formValues.idrcalc_select_plan === undefined
                ? ""
                : formValues.idrcalc_select_plan
            }
            onChange={onChange}
            className={styles.inputField}
          >
            <MenuItem value={0}>REPAYE/SAVE</MenuItem>
            <MenuItem value={1}>IBR</MenuItem>
            <MenuItem value={2}>IBR for new borrowers</MenuItem>
            <MenuItem value={3}>PAYE</MenuItem>
          </Select>
        </Box>
        <Box className={styles.inputRow}>
          <FormLabel>
            What rate of return would you expect to make on your investments?
          </FormLabel>
          <PercentTextField
            variant="outlined"
            name="idrcalc_breakeven_ret"
            value={formValues.idrcalc_breakeven_ret || ""}
            onChange={onChange}
          />
        </Box>
        <Button
          className="mb-2"
          fbColor="primary"
          onClick={() => submit()}
          fullWidth
        >
          Next
        </Button>
        <Button fbColor="secondary" onClick={goToStepOne} fullWidth>
          Back
        </Button>
      </Paper>
    </>
  );
};

export default PayoffVsIDRForm;
