import produce from "immer";
import { findIndex } from "lodash";
import { handleActions } from "redux-actions";

import {
  Account,
  AccountProvider,
  AccountState,
  Cashflow,
  FbAction,
  LinkedAccount,
  PublicToken,
} from "../../interfaces";
import { FAIL, START, SUCCESS } from "../common";
import { errorReducer, setLoading } from "../common/reducers";
import * as actions from "./actions";
import {
  ADD_CASHFLOW_ITEM,
  FETCH_CASHFLOW_ITEMS,
  REMOVE_CASHFLOW_ITEM,
} from "../cashflow/actions";
import { LOG_OUT } from "../system/actions";

const initialState: AccountState = {
  accounts: [],
  closedAccounts: [],
  loaded: false,
  loading: false,
  loadedProviders: false,
  loadedAccountDetails: false,
  loadingAccountDetails: false,
  loadingLinkedAccounts: false,
  linkedAccountsError: null,
  publicToken: {},
  newProviders: [],
  providers: [],
  linkedAccounts: [],
  referralCode: null,
};

const reducerDefinitions: any = {
  [actions.GET_ACCOUNT + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<Account>
  ) =>
    produce(state, (draft) => {
      draft.accounts.forEach((account, index) => {
        if (account.id === payload.id) {
          draft.accounts[index] = payload;
          draft.accounts[index].whose = payload.whose || payload.who;
        }
      });
    }),
  [actions.GET_PLAID_PUBLIC_TOKEN + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<PublicToken>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.publicToken = payload;
    }),
  [actions.GET_ACCOUNT_PROVIDERS + START]: (state: AccountState) =>
    produce(state, (draft) => {
      draft.loadingLinkedAccounts = true;
      draft.linkedAccountsError = false;
    }),

  [actions.GET_ACCOUNT_PROVIDERS + SUCCESS]: (
    state: AccountState,
    {
      payload,
    }: FbAction<{
      providers: AccountProvider[];
    }>
  ) =>
    produce(state, (draft) => {
      if (!draft.loadedProviders) {
        draft.loadedProviders = true;
        draft.providers = payload.providers;
      } else {
        state.providers.forEach((item, index) => {
          const matched = payload.providers.find(
            (found) => found.item_id === item.item_id
          );
          if (matched) {
            draft.providers[index] = matched;
          }
        });
        const newProviders = payload.providers.filter(
          (item) =>
            state.providers.findIndex(
              (found) => found.item_id === item.item_id
            ) < 0
        );
        draft.newProviders = newProviders;
        draft.providers.push(...newProviders);
        draft.providers = draft.providers.filter(
          (item) =>
            payload.providers.findIndex(
              (found) => found.item_id === item.item_id
            ) >= 0
        );
      }
    }),
  [actions.GET_LINKED_ACCOUNTS + SUCCESS]: (
    state: AccountState,
    {
      payload,
    }: FbAction<{
      linkedAccounts: LinkedAccount[];
    }>
  ) =>
    produce(state, (draft) => {
      draft.loadingLinkedAccounts = false;
      draft.linkedAccountsError = false;
      if (payload.linkedAccounts && payload.linkedAccounts.length) {
        draft.linkedAccounts = payload.linkedAccounts;
      }
    }),
  [actions.GET_ACCOUNT_PROVIDERS + FAIL]: (
    state: AccountState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.linkedAccountsError = payload;
    }),
  [actions.GET_LINKED_ACCOUNTS + FAIL]: (
    state: AccountState,
    { payload }: FbAction<any>
  ) =>
    produce(state, (draft) => {
      draft.linkedAccountsError = payload;
    }),
  [actions.GET_ACCOUNTS + START]: (state: AccountState) => ({
    ...state,
    loading: true,
  }),
  [actions.GET_ACCOUNTS + SUCCESS]: (
    state: AccountState,
    {
      payload,
    }: FbAction<{
      data: Account[];
      overwrite: boolean;
    }>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      draft.accounts = [];
      draft.closedAccounts = [];
      payload.data.forEach((account) => {
        if (account.variable === "closed") {
          draft.closedAccounts.push(account);
        } else {
          draft.accounts.push(account);
        }
      });
      if (!payload.overwrite && state.accounts.length) {
        draft.accounts = draft.accounts.map((newAccount) => {
          const existingAccount =
            state.accounts.find((account) => account.id === newAccount.id) ||
            {};
          return { ...existingAccount, ...newAccount };
        });
      }
    }),
  [actions.ADD_ACCOUNT + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<Account>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.accounts.push(payload);
    }),
  [actions.UPDATE_ACCOUNT + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<{ id: number; account: Account }>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      const accountIndex = findIndex(
        draft.accounts,
        (item: Account) => item.id === payload.id
      );
      if (accountIndex >= 0) {
        draft.accounts[accountIndex] = payload.account;
      }
    }),
  [actions.DELETE_ACCOUNT + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.accounts = state.accounts.filter(
        (account) => account.id !== payload
      );
    }),
  [actions.LOAD_ALL_ACCOUNT_DETAILS + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<Account[]>
  ) =>
    produce(state, (draft) => {
      draft.loadedAccountDetails = true;
      payload.forEach((newAccount) => {
        const existingAccountIndex = state.accounts.findIndex(
          (account) => account.id === newAccount.id
        );
        if (existingAccountIndex >= 0) {
          draft.accounts[existingAccountIndex] = newAccount;
        }
      });
    }),
  [FETCH_CASHFLOW_ITEMS + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<Cashflow[]>
  ) =>
    produce(state, (draft) => {
      const pseudoAssetCashflows = payload.filter(
        (item: Cashflow) => item.type === "vehicle_lease" && item.amount
      );
      if (!pseudoAssetCashflows.length) {
        return;
      }
      draft.accounts = draft.accounts.filter(
        (item: Account) => item.type !== "vehicle_lease"
      );
      pseudoAssetCashflows.forEach((item: Cashflow) =>
        draft.accounts.push({
          type: "vehicle_lease",
          balance: 0,
          payment: item.amount || 0,
          id: item.id,
          firm: "",
          name: "",
          who: "applicant",
          rate: 0,
          "asset-balance": 0,
          variable: "vehicle_lease",
        })
      );
    }),
  [ADD_CASHFLOW_ITEM + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<{ data: Cashflow }>
  ) =>
    produce(state, (draft) => {
      if (payload.data.type !== "vehicle_lease") {
        return;
      }
      draft.accounts.push({
        type: "vehicle_lease",
        balance: 0,
        payment: payload.data.amount || 0,
        id: payload.data.id,
        firm: "",
        name: "",
        who: "applicant",
        rate: 0,
        "asset-balance": 0,
        variable: "vehicle_lease",
      });
    }),
  [REMOVE_CASHFLOW_ITEM + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<number>
  ) =>
    produce(state, (draft) => {
      draft.accounts = state.accounts.filter(
        (item) => item.variable !== "vehicle_lease" || item.id !== payload
      );
    }),
  [actions.RELINK_LINKED_ACCOUNT + START]: (state: AccountState) => ({
    ...state,
    loading: true,
  }),
  [actions.RELINK_LINKED_ACCOUNT + SUCCESS]: (
    state: AccountState,
    { payload }: FbAction<AccountProvider>
  ) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.providers = state.providers.map((provider) => {
        if (provider.item_id === payload.item_id) {
          return {
            ...provider,
            error: payload.error,
          };
        }
        return provider;
      });
    }),
  [actions.MARK_PROVIDER_CONFIRMED]: (
    state: AccountState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.providers = state.providers.filter(
        (provider: any) => provider.id !== payload
      );
    }),
  [actions.SET_REFERRAL_CODE]: (
    state: AccountState,
    { payload }: FbAction<string>
  ) =>
    produce(state, (draft) => {
      draft.referralCode = payload;
    }),
  [actions.UPDATE_PROVIDER]: (
    state: AccountState,
    { payload }: FbAction<actions.UpdateProviderPayload>
  ) =>
    produce(state, (draft) => {
      draft.providers = state.providers.map((provider) => {
        if (provider.item_id === payload.itemId) {
          return {
            ...provider,
            ...payload.update,
          };
        }
        return provider;
      });
    }),
  [LOG_OUT]: () => initialState,
};

[
  actions.GET_ACCOUNTS,
  actions.ADD_ACCOUNT,
  actions.UPDATE_ACCOUNT,
  actions.GET_PLAID_PUBLIC_TOKEN,
].forEach((actionType) => {
  reducerDefinitions[actionType + START] = setLoading;
  reducerDefinitions[actionType + FAIL] = errorReducer;
});

const accountReducer = handleActions<AccountState, any>(
  reducerDefinitions,
  initialState
);

export default accountReducer;
