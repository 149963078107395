import { User } from "src/interfaces";
import axiosClient from "../axiosClient";
import makeAuthenticatedRequest from "../authenticatedRequest";

export const fetchLoginApi = async (email: string, password: string) => {
  const { data } = await axiosClient.post("auth/sign_in.php", {
    email,
    password,
  });
  const { token, refresh } = data;
  return { token, refresh };
};

export const forgotPasswordApi = (email: string) =>
  axiosClient.post("auth/forget.php", { email });

export const signupApi = async (
  email: string,
  password: string,
  first_name: string,
  last_name: string
) => {
  const { data } = await axiosClient.put("auth/signup.php", {
    email,
    password,
    first_name,
    last_name,
  });
  const { token, refresh } = data;
  return { token, refresh };
};

export const addSpouseApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("auth/spouse.php");
    return data;
  });

export const getUserApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get(
      "profile/settings.php?who=applicant"
    );
    return data;
  });

export const getSpouseApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("profile/settings.php?who=spouse");
    return data;
  });

export const updateUserApi = (payload: Partial<User>) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.patch("profile/settings.php", payload);
    return data;
  });

export const getFeaturesApi = () =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.get("features.php");
    const {
      spouse: married,
      plan: hasPlan,
      subscription: subscribed,
      inschool,
      "access-plan": planBuilder,
    } = data;
    return {
      married,
      hasPlan: subscribed && hasPlan,
      inschool,
      subscribed,
      planBuilder,
    };
  });

export const inviteSpouseApi = (
  email: string,
  first_name?: string,
  last_name?: string
) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("invite.php", {
      email,
      first_name,
      last_name,
    });
    return data;
  });

export const authorizeSpouseApi = (email: string, password: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.put("auth/spouse.php", {
      email,
      password,
    });
    return data;
  });

export const changePasswordApi = (
  currentPassword: string,
  newPassword: string
) =>
  makeAuthenticatedRequest(() =>
    axiosClient.put("auth/password.php", {
      current: currentPassword,
      new: newPassword,
    })
  );

export const resetPasswordApi = (resetkey: string, password: string) =>
  axiosClient.post("auth/reset.php", { resetkey, password });

export const setGraduatedModeApi = () =>
  makeAuthenticatedRequest(() => Promise.resolve());

export const subscribeCurriculumApi = (date: string) =>
  makeAuthenticatedRequest(async () => {
    const { data } = await axiosClient.post("v2/subscribe/curriculum", {
      grad_date: date,
    });
    return data;
  });
