import * as React from "react";

import { makeStyles } from "@material-ui/core";

import DashboardLayout from "src/layouts/DashboardLayout";
import { headerStyles } from "src/theme";

import { Content } from "./Content";

const useStyles = makeStyles(headerStyles);

export const OtherItems = ({ data, title }: any) => {
  const classes = useStyles();
  return (
    <DashboardLayout pageTitle={title}>
      <main className={classes.mainContent}>
        <Content data={data} />
      </main>
    </DashboardLayout>
  );
};

export default OtherItems;
