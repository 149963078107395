import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container } from "@material-ui/core";
import MainCard from "src/components/MainCard";

import {
  ASSET_TYPES,
  PROFILE_DEBT_TYPES,
  FbAction,
} from "src/interfaces/common";
import { NOT_APPLICABLE } from "src/constants";
import {
  getAllFormattedAssetsAndDebts,
  getLoadedAccountDetails,
} from "src/store/account/selector";
import {
  addAccount,
  getAccounts,
  deleteAccount,
  updateAccount,
} from "src/store/account/actions";
import { getScore } from "src/store/dashboard/actions";
import {
  addCashflow,
  editCashflow,
  removeCashflow,
} from "src/store/cashflow/actions";
import {
  setProfileProgress,
  setProfileStep,
  updateDebts,
  updateHousehold,
} from "src/store/profileBuild/actions";
import {
  PROFILE_BUILD_STEPS,
  PROFILE_PROGRESS,
} from "src/store/profileBuild/constants";
import {
  getHousehold,
  getProfileProgress,
} from "src/store/profileBuild/selector";
import { getIsMarried } from "src/store/system/selector";
import {
  Account,
  MappedAccount,
  DebtsSection,
  ViewComponent,
} from "src/interfaces";
import AddAssetOrDebt from "./AddAssetOrDebt";
import { AccountFormValues } from "./components/interfaces";
import { SPECIAL_REPAYMENTS } from "src/interfaces";
import { formatDollars } from "src/utils";

const MainForm: ViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const isMarried = useSelector(getIsMarried);
  const household = useSelector(getHousehold);
  const profileProgress = useSelector(getProfileProgress);
  const { assets, debts } = useSelector(getAllFormattedAssetsAndDebts);
  const loadedAccountDetails = useSelector(getLoadedAccountDetails);
  const [addingItem, setAddingItem] = useState(false);
  const [isDebt, setIsDebt] = useState(false);
  const initialValues: AccountFormValues = {
    balance: 0,
    type: "other_assets_value",
    who: "applicant",
    filing_jointly: household.filing_jointly,
  };
  const [formState, setFormState] = useState<AccountFormValues>(initialValues);

  useEffect(() => {
    if (isMarried && !loadedAccountDetails) {
      dispatch(getAccounts());
    }
  }, [isMarried, loadedAccountDetails]);

  const refreshScore = () =>
    window.setTimeout(() => dispatch(getScore()), 1000);

  const updateField = (e: React.ChangeEvent<any>) => {
    const field = e.target.name;
    const value: any = e.target.value;
    const update = { ...formState, [field]: value };
    if (field === "student_loan_prepayments") {
      if (value === "y") {
        update.student_loan_prepayments_type = "1";
      } else {
        update.student_loan_prepayments_type = "";
      }
    }
    setFormState({ ...formState, [field]: value });
  };

  const saveItem = (item: MappedAccount, updateFields: any) => {
    const update = { ...item, ...updateFields };
    if (item.id >= 0) {
      if (item.variable === "vehicle_lease") {
        const payload: any = {
          id: item.id,
          amount: item.annual,
        };
        dispatch(editCashflow(payload));
        setAddingItem(false);
      } else {
        const value = Math.abs(update.balance || 0);
        const payload: any = {
          id: item.id,
          update: {},
        };
        if (item.variable === "credit_card") {
          payload.update.balance_live = value;
        } else {
          payload.update.balance = value;
        }
        dispatch(updateAccount(payload));
        setAddingItem(false);
      }
    }
    // refreshScore();
  };

  const removeItem = (item: Account) => {
    if (item.variable === "vehicle_lease") {
      return dispatch(removeCashflow(item.id));
    }
    // refreshScore();
    const update: any = {};
    if (item.variable === "credit_card") {
      update.balance_live = 0;
    } else {
      update.balance = 0;
    }
    return dispatch(deleteAccount(item.id));
  };

  const onNext = () => {
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.RISK_MANAGEMENT_INTRO));
    refreshScore();
  };
  const onPrev = () => {
    dispatch(setProfileStep(PROFILE_BUILD_STEPS.INCOME_EXPENSES));
    refreshScore();
  };

  const addDebt = (label: any, key: any) => {
    setIsDebt(true);
    setAddingItem(true);
    setFormState({
      type: key,
      typeLabel: label,
      balance: null,
      rate: 0,
      payment: 0,
      who: "applicant",
    });
  };
  const addAsset = (label: any, key: any) => {
    setIsDebt(true);
    setAddingItem(true);
    setFormState({
      type: key,
      typeLabel: label,
      balance: 0,
      who: "applicant",
    });
  };

  const cancelAddDebt = () => null;

  const cancelAddAsset = () => null;

  const save = () => {
    const accountInputs: Array<Partial<Account>> = [];
    const actions: Array<FbAction<any>> = [];
    if (profileProgress < PROFILE_PROGRESS.ASSETS) {
      actions.push(setProfileProgress(PROFILE_PROGRESS.ASSETS));
    }
    const cashflow = {
      amount: formState.payment || 0,
      type: "vehicle_lease",
      who: formState.who || "applicant",
    };
    const updateProfilePayload: Partial<DebtsSection> = {
      student_loan_prepayments: formState.student_loan_prepayments || "n",
    };
    const generalInput: any = {
      type: formState.type,
      balance: formState.balance,
      rate: formState.rate,
      payment: formState.payment || 0,
      who: formState.who || "applicant",
    };
    switch (formState.type) {
      case "vehicle_lease":
        actions.push(addCashflow({ cashflow, temporaryId: 0 }));
        setAddingItem(false);
        break;
      case "auto_value":
      case "auto_loan":
        accountInputs.push({
          type: "auto_value",
          balance: formState.auto_value,
          who: formState.who || "applicant",
        });
        if (formState.type === "auto_loan" || formState.has_auto_loan) {
          accountInputs.push({
            type: "auto_loan",
            balance: formState.balance,
            payment: formState.payment,
            rate: formState.rate,
            who: formState.who || "applicant",
          });
        }
        break;
      case "home_value":
      case "home_loan":
        accountInputs.push({
          type: "home_value",
          balance: formState.home_value,
          who: formState.who,
        });
        if (formState.type === "home_loan" || formState.has_home_mtg) {
          accountInputs.push({
            type: "home_loan",
            balance: formState.balance,
            payment: formState.payment,
            rate: formState.rate,
            who: formState.who || "applicant",
          });
        }
        break;
      case "property_value":
      case "property_loan":
        accountInputs.push({
          type: "property_value",
          balance: formState.inv_prop_value,
          who: formState.who || "applicant",
        });
        if (formState.type === "property_loan" || formState.has_rental_mtg) {
          accountInputs.push({
            type: "property_loan",
            balance: formState.balance,
            payment: formState.payment,
            rate: formState.rate,
            who: formState.who || "applicant",
          });
        }
        break;
      case "fed_loan":
      case "priv_loan":
        accountInputs.push({
          type: formState.type,
          balance: formState.balance,
          rate: formState.rate,
          payment: formState.payment,
          who: formState.who || "applicant",
        });
        if (formState.student_loan_prepayments === "y") {
          updateProfilePayload.student_loan_prepayments_type =
            formState.student_loan_prepayments_type || "1";
        }
        if (formState.type === "fed_loan") {
          updateProfilePayload.fed_repayment_plan =
            formState.fedloan_repayment_plan;
          // if (formState.addl_fedloan_balance) {
          //   accountInputs.push({
          //     type: "fed_loan",
          //     balance: formState.addl_fedloan_balance,
          //     who: formState.who || "applicant",
          //   });
          // }
          if (
            isMarried &&
            SPECIAL_REPAYMENTS.indexOf(
              formState.fedloan_repayment_plan || ""
            ) >= 0
          ) {
            actions.push(
              updateDebts({
                update: {
                  fed_repayment_plan: formState.fedloan_repayment_plan,
                },
                who: formState.who === "spouse" ? "spouse" : "applicant",
              })
            );
            if (formState.filing_jointly !== household.filing_jointly) {
              actions.push(
                updateHousehold({
                  update: { filing_jointly: formState.filing_jointly },
                })
              );
            }
          }
        }
        actions.push(
          updateDebts({
            update: updateProfilePayload,
            who: formState.who || "applicant",
          })
        );
        break;
      default:
        if (formState.type === "credit_card") {
          generalInput.carrying =
            formState.balance && formState.balance > 0 ? "y" : "n";
          generalInput.balance_live = formState.balance;
        }
        accountInputs.push(generalInput);
    }
    accountInputs.forEach((input) =>
      actions.push(
        addAccount({
          account: input,
        })
      )
    );
    actions.forEach(dispatch);
    // refreshScore();
    setAddingItem(false);
  };

  const formatDollarsEmptyNA = (amount: number) => {
    if (!amount) {
      return NOT_APPLICABLE;
    }
    return formatDollars(amount);
  };

  // const formatMonthlyEmptyNA = (amount: number) => {
  //   if (!amount) {
  //     return NOT_APPLICABLE;
  //   }
  //   return formatMonthly(amount);
  // };

  if (!render) {
    return <div />;
  }

  const readOnlyDebts: number[] = [];
  const readOnlyAssets: number[] = [];
  debts.forEach((account, index) => {
    if (account.manual && !account.manual.balance) {
      readOnlyDebts.push(index);
    }
  });
  assets.forEach((account, index) => {
    if (account.manual && !account.manual.balance) {
      readOnlyAssets.push(index);
    }
  });

  const displayDebts = debts
    .filter(
      (debt) => debt.variable === "vehicle_lease" || (debt?.balance || 0) > 0
    )
    .map((debt) => {
      if (!isMarried || debt.variable !== "fed_loan") {
        return debt;
      }
      return {
        ...debt,
        variableLabel: `Federal Student Loan (${
          debt.who === "spouse" ? "Spouse" : "Mine"
        })`,
      };
    });

  return render({
    component: addingItem ? (
      <AddAssetOrDebt {...{ formState, setFormState, isDebt, updateField }} />
    ) : (
      <Container className="pb-32">
        <MainCard
          iconName="fb-scales-tripped"
          title="Debts"
          columns={[
            {
              label: "",
              field: "variable",
              type: "fixed",
              width: "60%",
              items: PROFILE_DEBT_TYPES,
            },
            {
              label: "Balance",
              field: "balance",
              type: "number",
              width: "40%",
              formatter: formatDollarsEmptyNA,
            },
            // {
            //   label: "",
            //   field: "monthly",
            //   type: "number",
            //   width: "30%",
            //   formatter: formatMonthlyEmptyNA,
            // },
          ]}
          data={displayDebts}
          addItems={PROFILE_DEBT_TYPES}
          onAddFromSelect={addDebt}
          onCancelEdit={cancelAddDebt}
          onDelete={removeItem}
          onSave={saveItem}
          fixedRows={readOnlyDebts}
        />
        <MainCard
          iconName="fb-model"
          title="Assets"
          columns={[
            {
              label: "",
              field: "variable",
              type: "fixed",
              width: "60%",
              items: ASSET_TYPES,
            },
            {
              label: "Balance",
              field: "balance",
              type: "number",
              width: "40%",
              formatter: formatDollars,
            },
          ]}
          data={assets}
          addItems={ASSET_TYPES}
          onAddFromSelect={addAsset}
          onCancelEdit={cancelAddAsset}
          onDelete={removeItem}
          onSave={saveItem}
          fixedRows={readOnlyAssets}
        />
      </Container>
    ),
    onNext: addingItem ? save : onNext,
    onPrev: addingItem ? () => setAddingItem(false) : onPrev,
  });
};

export default MainForm;
