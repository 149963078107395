import * as React from "react";
import { makeStyles } from "@material-ui/core";

import ProfileBuilderLayout from "src/layouts/ProfileBuilderLayout";
import { headerStyles } from "../../theme";
import { Content } from "./Content";

const useStyles = makeStyles(headerStyles);

const ProfileBuilder = () => {
  const classes = useStyles();
  return (
    <ProfileBuilderLayout pageTitle="Profile builder">
      <main className={classes.mainContent}>
        <Content />
      </main>
    </ProfileBuilderLayout>
  );
};

export default ProfileBuilder;
