import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";

import {
  addSpouseApi,
  authorizeSpouseApi,
  fetchLoginApi,
  getFeaturesApi,
  getSpouseApi,
  getUserApi,
  signupApi,
  subscribeCurriculumApi,
  updateProfileApi,
  updateUserApi,
} from "src/apiService";
import {
  getAuthToken,
  removeAuthTokens,
  setAuthToken,
  setRefreshToken,
} from "src/config";

import { FbAction, User } from "src/interfaces";
import { FAIL, START, SUCCESS } from "../common";
import {
  ADD_SPOUSE,
  AddSpousePayload,
  FETCH_LOGIN,
  FETCH_USER,
  UPDATE_USER,
  LOG_OUT,
  SIGNUP,
  LoginPayload,
  SignupPayload,
} from "./actions";
import { isLoggedInSelector } from "./selector";
import { CustomerSignup } from "src/tapfiliate";
import { loadDashboardData } from "../dashboard/actions";
import { fetchHousehold } from "../profileBuild/actions";
import { identify } from "src/utils/tracking";

interface LoginResponse {
  token: string;
  refresh: string;
}

function* getUser(): any {
  try {
    const [user, features] = yield all([
      call(getUserApi),
      call(getFeaturesApi),
    ]);
    let spouse = null;
    if (features.married) {
      spouse = yield call(getSpouseApi);
    }
    yield put({
      type: FETCH_USER + SUCCESS,
      payload: { user, spouse, features },
    });
  } catch (error) {
    yield put({ type: FETCH_USER + FAIL, payload: error });
  }
}

function* updateUser({ payload }: FbAction<User>): any {
  try {
    yield call(updateUserApi, payload);
    yield put({ type: FETCH_USER + SUCCESS, payload: { user: payload } });
  } catch (error) {
    yield put({ type: UPDATE_USER + FAIL, payload: error });
  }
}

function* login({ payload }: FbAction<LoginPayload>) {
  try {
    const data: LoginResponse = yield call(
      fetchLoginApi,
      payload.email,
      payload.password
    );
    setAuthToken(data.token);
    setRefreshToken(data.refresh);
    yield put({ type: FETCH_LOGIN + SUCCESS, payload: data });
    identify(payload.email);
  } catch (error) {
    let result = (error as any).response;
    if (!result?.message && result?.status === 400) {
      result.message = "Incorrect Email or Password!"; // TODO: friendly message
    } else {
      result = { message: "Something went wrong!" };
    }
    yield put({ type: FETCH_LOGIN + FAIL, payload: result });
  }
}

function* signup({ payload }: FbAction<SignupPayload>): any {
  try {
    const data: LoginResponse = yield call(
      signupApi,
      payload.email,
      payload.password,
      payload.firstName,
      payload.lastName
    );
    setAuthToken(data.token);
    CustomerSignup();
    setRefreshToken(data.refresh);
    if (payload.curriculum && payload.program) {
      const updateProfilePayload: any =
        payload.program.degree === 2
          ? {
              undergrad: "y",
              undergrad_school: payload.program.school,
              undergrad_grad_month: payload.gradmonth,
              undergrad_grad_year: payload.gradyear,
            }
          : {
              adv_deg: "y",
              adid: payload.program.degree,
              adv_school: payload.program.school,
              date_last_school_month: payload.gradmonth,
              date_last_school_year: payload.gradyear,
            };
      yield call(updateProfileApi, { profile: updateProfilePayload });
      yield call(
        subscribeCurriculumApi,
        `${payload.gradyear}-${
          (payload.gradmonth as number) < 10
            ? "0" + payload.gradmonth
            : payload.gradmonth
        }`
      );
    }
    const N_POLLS_FOR_SUBSCRIPTION = 3;
    let features: any = {
      married: false,
      hasPlan: false,
      subscribed: false,
      inschool: false,
      planBuilder: false,
    };
    for (let i = 0; i < N_POLLS_FOR_SUBSCRIPTION; i++) {
      features = yield call(getFeaturesApi);
      if (features.subscribed) {
        break;
      }
      yield delay(1000);
    }
    yield put({ type: SIGNUP + SUCCESS, payload: { ...data, features } });
    identify(payload.email, 'sign_up_completed');
  } catch (error) {
    // TODO: parse error (move to utils ?)
    let payload = (error as any).response;
    if (!payload?.message && payload?.status === 409) {
      payload.message = "Email already exists!"; // TODO: friendly message
    } else {
      payload = { message: "Something went wrong!" };
    }
    yield put({ type: SIGNUP + FAIL, payload });
  }
}

function* addSpouse({ payload }: FbAction<AddSpousePayload>) {
  try {
    const { spouse, newSpouse } = payload;
    if (spouse) {
      yield call(authorizeSpouseApi, spouse.email, spouse.password);
      yield put(fetchHousehold());
    } else if (newSpouse) {
      yield call(addSpouseApi);
      yield call(updateProfileApi, {
        who: "spouse",
        profile: newSpouse,
      } as any);
    }
    yield put({ type: ADD_SPOUSE + SUCCESS, payload: { linked: !!spouse } });
    yield getUser();
    yield put(loadDashboardData());
  } catch (error) {
    yield put({ type: ADD_SPOUSE + FAIL, payload: error });
  }
}

function* logout() {
  yield removeAuthTokens();
}

function* checkToken() {
  while (true) {
    yield delay(5000);
    const loggedIn: boolean = yield select(isLoggedInSelector);
    const token = getAuthToken();
    if (loggedIn && !token) {
      yield put({ type: LOG_OUT });
    }
  }
}

export function* appSagas() {
  yield all([
    takeLatest(FETCH_USER + START, getUser),
    takeLatest(UPDATE_USER + START, updateUser),
    takeLatest(FETCH_LOGIN + START, login),
    takeLatest(SIGNUP + START, signup),
    takeLatest(ADD_SPOUSE + START, addSpouse),
    takeLatest(LOG_OUT, logout),
    checkToken(),
  ]);
}
