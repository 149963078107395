import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import HeaderText from "src/components/HeaderText";
import AddAccountCard from "./components/AddAccountCard";
import { newPlanContent } from "./data";
import AccountsListCard from "./components/AccountsListCard";
import AddEditManualAccountDialog from "./components/AddEditManualAccountDialog";
import { ConfirmDialog } from "src/components";
import { Account } from "src/interfaces";
import AccountLinkPage from "./components/AccountLinkPage";
import {
  getAccountProviders as fetchAccountProviders,
  getAccounts as fetchAccounts,
} from "src/store/account/actions";
import {
  getAccountProviders,
  getAccounts,
  getAllFormattedAssetsAndDebts,
  getLinkedAccounts,
} from "src/store/account/selector";

export const Content = () => {
  const dispatch = useDispatch();
  const rawAccounts: Account[] = useSelector(getAccounts);
  const { assets: rawAssets, debts: rawDebts }: any = useSelector(
    getAllFormattedAssetsAndDebts
  );
  const { heading, description } = newPlanContent;
  const linkedAccounts = useSelector(getLinkedAccounts);
  const providers = useSelector(getAccountProviders);

  useEffect(() => {
    dispatch(fetchAccountProviders());
    dispatch(fetchAccounts());
  }, []);

  const isLinkedAccount = (id: number) => {
    return linkedAccounts.findIndex((e) => e.id === id) > -1;
  };

  const accounts = rawAccounts.filter(
    (account) => account.variable !== "vehicle_lease"
  );
  const assets = rawAssets.filter(
    (account: any) => account.variable !== "vehicle_lease"
  );
  const debts = rawDebts.filter(
    (account: any) => account.variable !== "vehicle_lease"
  );

  const hasError = (id: number) => {
    const linkedAccount = linkedAccounts.find((e) => e.id === id);
    if (linkedAccount) {
      const provider = providers.find(
        (e) => e.item_id === linkedAccount.item_id
      );
      return !!provider?.error;
    }
    return false;
  };

  const [
    addEditManualAccountDialogVisible,
    setAddEditManualAccountDialogVisible,
  ] = useState<boolean>(false);
  const [
    relinkLinkedAccountDialogVisible,
    setRelinkLinkedAccountDialogVisible,
  ] = useState<boolean>(false);
  const [
    addConfirmDialogVisible,
    setAddConfirmDialogVisible,
  ] = useState<boolean>(false);
  const [isLinked, setIsLinked] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();

  const selectedLinkedAccount = useMemo(() => {
    if (!selectedAccount) {
      return undefined;
    }
    return linkedAccounts.find((item) => item.id === selectedAccount.id);
  }, [selectedAccount, linkedAccounts]);

  useEffect(() => {
    if (!addEditManualAccountDialogVisible) {
      setSelectedAccount(undefined);
    }
  }, [addEditManualAccountDialogVisible]);

  const handleAddAccount = (manual: boolean) => {
    if (manual) {
      setIsLinked(false);
      setSelectedAccount(undefined);
      setAddEditManualAccountDialogVisible(true);
    }
  };

  const handleContinue = (res: boolean) => {
    if (res) {
      setAddConfirmDialogVisible(false);
      setAddEditManualAccountDialogVisible(true);
    } else {
      setAddConfirmDialogVisible(false);
      setAddEditManualAccountDialogVisible(false);
    }
  };

  const handleEdit = (id: number) => {
    if (accounts && id) {
      const index = accounts.findIndex((account) => account.id === id);
      if (index > -1) {
        setSelectedAccount(accounts[index]);
        if (isLinkedAccount(id)) {
          setIsLinked(true);
          if (hasError(id)) {
            setRelinkLinkedAccountDialogVisible(true);
            return;
          }
        } else {
          setIsLinked(false);
        }
        setAddEditManualAccountDialogVisible(true);
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <AddAccountCard onAdd={handleAddAccount} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="flex justify-start items-center md:px-10"
        >
          <HeaderText heading={heading} description={description} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AccountsListCard
            title="Asset Accounts"
            data={assets}
            isAsset={true}
            onEdit={handleEdit}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AccountsListCard
            title="Debt Accounts"
            data={debts}
            onEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <AddEditManualAccountDialog
        open={addEditManualAccountDialogVisible}
        selected={selectedAccount}
        setSelected={setSelectedAccount}
        isLinked={isLinked}
        closeOnSave
        onClose={() => {
          setAddEditManualAccountDialogVisible(false);
        }}
      />
      <AccountLinkPage
        fixMode
        startingProviderId={(selectedLinkedAccount as any)?.item_id || ""}
        isOpen={relinkLinkedAccountDialogVisible}
        onClose={() => {
          setRelinkLinkedAccountDialogVisible(false);
        }}
      />
      <ConfirmDialog
        visible={addConfirmDialogVisible}
        title="Add More Accounts"
        message="Would you like to add more accounts?"
        onCancel={() => handleContinue(false)}
        onConfirm={() => handleContinue(true)}
        cancelButton="No, Thank You"
        confirmButton="Yes, Please"
      />
    </>
  );
};

export default Content;
