import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Card from "src/components/Card";
import Chart from "src/components/Chart";
import ScoreReport from "src/components/ScoreReport";
import ActionItems from "./Blocks/ActionItems";
import ExecutionSummary from "./ExecutionSummary";
import PlanDetails from "./PlanDetails";
import EventsGoals from "./EventsGoals";
import InvestmentAllocationCard from "./InvestmentAllocationCard";
import { getAccounts } from "src/store/account/actions";
import { fetchLivePlanProjection } from "src/store/planBuild/actions";
import {
  getStudentIncomeAndExpenseSummaries,
  loadPlanSummary,
  loadStudentPlanSummary,
} from "src/store/dashboard/actions";
import { getScore, getRefreshNeeded } from "src/store/dashboard/selector";
import {
  getDashboardScoreProjection,
  getLiveLifeEventProjections,
  getLiveProjection,
} from "src/store/planBuild/selector";
import { getLastGraduationYearMonth } from "src/store/profileBuild/selector";
import { getIsCurrentStudent } from "src/store/system/selector";
import { AdapterLink } from "src/utils";
import { colors } from "src/theme";

const useStyles = makeStyles({
  eventsContainer: {
    margin: "20px 0px 0px 30px",
  },
  eventsContainerStudent: {
    margin: "20px 0px 0px 30px",
    maxWidth: 400,
  },
  executionSummary: {
    margin: "0px auto",
    maxWidth: 400,
  },
  executionSummaryStudent: {
    margin: "20px auto",
    maxWidth: 670,
  },
  executionGoals: {
    width: "100%",
  },
  overviewContainer: {
    width: 900,
    marginBottom: "60px",
  },
  explainer: {
    margin: "20px auto",
    maxWidth: 670,
  },
  tabs: {
    margin: "20px auto 0",
  },
  bottomBar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    backgroundColor: "white",
    margin: "0px -30px",
    padding: "10px 0px",
  },
  editButton: {
    display: "block",
    margin: "0 auto",
    width: 200,
  },
  loading: {
    margin: "100px auto",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    fontSize: "0.8em",
    color: colors.brandingBlue1,

    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const nowYear = new Date().getUTCFullYear();

const PlanSummaryContainer = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const score = useSelector(getScore);
  const scoreProjection = useSelector(getDashboardScoreProjection);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const livePlanEvents: any[] = useSelector(getLiveLifeEventProjections);
  const liveProjection = useSelector(getLiveProjection);
  const isCurrentStudent = useSelector(getIsCurrentStudent);
  const [graduationYear, graduationMonth] = useSelector(
    getLastGraduationYearMonth
  );
  const planStart = liveProjection?.start || `${nowYear}-01`;
  const startMonth = +planStart.slice(5);
  const startYear = +planStart.slice(0, 4);

  useEffect(() => {
    if (refreshNeeded.liveProjection) {
      dispatch(fetchLivePlanProjection(false));
    }
  }, [refreshNeeded.liveProjection]);

  const loadSummary = (year: number) => {
    const summaryArgs = { year: year || nowYear, startMonth: 0, endMonth: 0 };
    if (isCurrentStudent) {
      if (startYear === nowYear) {
        summaryArgs.startMonth = +planStart.slice(5, 7);
      }
      if (graduationYear === year) {
        summaryArgs.endMonth = graduationMonth;
      }
      dispatch(loadStudentPlanSummary(summaryArgs));
      dispatch(getStudentIncomeAndExpenseSummaries(summaryArgs));
    } else {
      dispatch(loadPlanSummary());
    }
  };

  useEffect(() => {
    loadSummary(startYear);
    dispatch(getAccounts());
  }, []);

  const getStyle = (section: string) => {
    let styleName = section;
    styleName = isCurrentStudent ? styleName + "Student" : styleName;
    return styles[styleName as keyof typeof styles];
  };

  return (
    <>
      {!liveProjection && (
        <Box className={styles.loading}>
          <Typography className="mb-8" variant="h2" component="h2">
            Loading plan summary...
          </Typography>
          <Box style={{ margin: "0 auto", width: 200 }}>
            <CircularProgress size={150} />
          </Box>
        </Box>
      )}
      {!!liveProjection && (
        <Box>
          <Grid container spacing={2} className="align-stretch">
            <Grid item xs={12} md={6}>
              <Card
                title="My Financial Plan Summary"
                headComponent={
                  <Box className="w-36">
                    <AdapterLink className={styles.link} to="/scorefactors">
                      Explore score factors
                    </AdapterLink>
                  </Box>
                }
              >
                <Box className="w-full mx-auto pt-4">
                  <Chart
                    borderRadius={0}
                    data={scoreProjection}
                    elevation={0}
                    height="222px"
                    keys={["score"]}
                    xKey="month"
                    keyLabels={{
                      score: "Current Projected",
                    }}
                    startMonth={startMonth}
                    startYear={startYear}
                    yLabelMargin={25}
                    width="100%"
                  />
                </Box>
                <ScoreReport
                  score={score}
                  live
                  liveScore={scoreProjection?.[0]?.score}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6} className="h-1/1">
              <ActionItems />
            </Grid>
          </Grid>

          {isCurrentStudent && (
            <Box className={styles.explainer}>
              <Typography variant="body1" paragraph>
                Below is a summary of how you are doing in regards to executing
                your plans. To see more details or to enter details, expand the
                rows and click the magnifying glass icon.
              </Typography>
            </Box>
          )}
          <Box className={getStyle("executionGoals")}>
            {isCurrentStudent ? <ExecutionSummary /> : <PlanDetails />}
          </Box>
          <Grid container spacing={2} className="mt-5 pb-8">
            <Grid item xs={12} md={6}>
              {!!livePlanEvents && !!livePlanEvents.length && <EventsGoals />}
            </Grid>
            <Grid item xs={12} md={6}>
              <InvestmentAllocationCard />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PlanSummaryContainer;
