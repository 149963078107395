import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";

import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import { Alert, Autocomplete } from "@material-ui/lab";

import TextField from "src/components/TextField";
import Checkbox from "src/components/Checkbox";
import Button from "src/components/Button";
import Icon from "src/components/Icon";

import { getCurriculumOptionsApi } from "src/apiService";
import { MONTHS, YEARS_FROM_NOW } from "src/constants";
import { signup } from "src/store/system/actions";
import { loginStateSelector } from "src/store/system/selector";
import { AdapterLink, validateEmail, validatePassword } from "src/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white,
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    autoText: {
      height: 45,
      "&>div": {
        padding: "6px !important",
      },
      "&>legend": {
        visibility: "visible",
      },
    },
  })
);

interface MyFormValues {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  program?: string;
  gradmonth?: any;
  gradyear?: any;
}

interface RegisterProps {
  curriculum?: boolean;
}

const DEGREE_NAMES: any = {
  2: "Undergrad",
  13: "MOT",
  23: "OTD",
  11: "DPT",
  3: "MBA",
  4: "JD",
  5: "MSc/MSE/MEng",
  7: "MD/DO",
  12: "DDS/DDM",
  18: "PA",
  999: "Master's",
};

const Register = ({ curriculum }: RegisterProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loggedIn, loading, error } = useSelector(loginStateSelector);
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [availablePrograms, setAvailablePrograms] = useState<any>([]);
  const [formValues, setFormValues] = useState<MyFormValues>({
    password: "",
    email: "",
    firstName: "",
    lastName: "",
    gradmonth: "",
    gradyear: "",
  });

  useEffect(() => {
    if (curriculum) {
      getCurriculumOptionsApi().then((result) => {
        setAvailablePrograms(
          result.sort((a: any, b: any) => {
            if (a.name === b.name) {
              if (DEGREE_NAMES[a.degree] > DEGREE_NAMES[b.degree]) {
                return 1;
              }
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
          })
        );
      });
    }
  }, [curriculum]);

  if (loggedIn) {
    return <Redirect to="/plan-summary" />;
  }

  const handleFormChange = (e: any) => {
    setFormValues((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  };

  const submit = () => {
    if (
      formValues.firstName !== "" &&
      formValues.lastName !== "" &&
      validateEmail(formValues.email) &&
      validatePassword(formValues.password) &&
      !loading
    ) {
      let program = undefined;
      if (curriculum) {
        program = availablePrograms.find(
          (item: any) =>
            `${item.school} - ${item.degree}` === formValues.program
        );
      }
      dispatch(signup({ ...formValues, curriculum, program }));
      // dispatch(setVideo("post_signup"));
    }
  };

  const toggleAcceptedPolicy = () => setAcceptedPolicy((current) => !current);
  const privacyPolicyLink = (
    <a href="https://www.fitbux.com/privacy-policy/" target="_blank">
      Privacy Policy
    </a>
  );
  const termsOfUseLink = (
    <a href="https://www.fitbux.com/terms-of-use/" target="_blank">
      Terms of Use
    </a>
  );
  const disclosureConsentLink = (
    <a
      href="https://www.fitbux.com/statement-of-electronic-disclosures/"
      target="_blank"
    >
      consent to Electronic Disclosures
    </a>
  );

  const displayError = error || passwordError || emailError;
  const ready =
    acceptedPolicy &&
    formValues.firstName &&
    formValues.lastName &&
    formValues.password &&
    (!curriculum ||
      (formValues.program && formValues.gradmonth && formValues.gradyear));
  return (
    <Container maxWidth="xs">
      <form className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          placeholder="First"
          autoFocus
          onChange={handleFormChange}
          value={formValues.firstName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon iconName="fb-user" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          placeholder="Last"
          autoFocus
          onChange={handleFormChange}
          value={formValues.lastName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon iconName="fb-user " />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          error={!!emailError}
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          type="email"
          placeholder="Email"
          onFocus={() => setEmailError("")}
          onChange={handleFormChange}
          value={formValues.email}
          onBlur={() => {
            if (!validateEmail(formValues.email)) {
              setEmailError("Please enter a valid email address.");
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon iconName="fb-at" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          error={!!passwordError}
          name="password"
          label="Password"
          type="password"
          id="password"
          placeholder="Password"
          autoComplete="current-password"
          onChange={handleFormChange}
          onFocus={() => setPasswordError("")}
          onBlur={() => {
            if (!validatePassword(formValues.password)) {
              setPasswordError(
                "Password must be at least 8 characters long and include at least one lowercase letter, one uppercase letter, and one numeral."
              );
            }
          }}
          value={formValues.password}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon iconName="fb-password" />
              </InputAdornment>
            ),
          }}
        />
        {curriculum && (
          <>
            <TextField
              className="mt-4"
              select
              variant="outlined"
              required
              fullWidth
              name="program"
              label="University &amp; program"
              id="program"
              onChange={handleFormChange}
              placeholder="Select..."
              value={formValues.program}
            >
              {availablePrograms.map((item: any) => (
                <MenuItem value={`${item.school} - ${item.degree}`}>
                  {item.name} - {DEGREE_NAMES[item.degree]}
                </MenuItem>
              ))}
            </TextField>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">When do you graduate? *</FormLabel>
              <Box className="flex mt-2 justify-between">
                <Select
                  placeholder="Month"
                  variant="outlined"
                  style={{ width: "47%", fontSize: "13px" }}
                  name="gradmonth"
                  id="gradmonth"
                  onChange={handleFormChange}
                  value={formValues.gradmonth}
                >
                  {MONTHS.map((month: string, index: number) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
                <Autocomplete
                  id="gradyear"
                  // name={item.field}
                  options={YEARS_FROM_NOW.slice(0, 9)}
                  getOptionLabel={(option) => "" + option}
                  style={{ width: "47%" }}
                  placeholder="Year"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Year"
                      name="gradyear"
                      className={classes.autoText}
                    />
                  )}
                  onChange={(...args) => {
                    handleFormChange({
                      target: { name: "gradyear", value: args[1] },
                    });
                  }}
                  value={formValues.gradyear}
                />
              </Box>
            </FormControl>
            <Grid container>
              <Grid item className="text-center w-full mb-2">
                <AdapterLink
                  to="/register"
                  variant="body2"
                  className="text-gray-3"
                >
                  Already graduated? Click here instead to sign up.
                </AdapterLink>
              </Grid>
            </Grid>
          </>
        )}
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={
            <Typography variant="body1">
              I acknowledge and agree to FitBUX's {privacyPolicyLink},{" "}
              {termsOfUseLink}, and {disclosureConsentLink}.
            </Typography>
          }
          checked={acceptedPolicy}
          onChange={toggleAcceptedPolicy}
        />
        {displayError && !loading && (
          <Alert severity="error">{displayError}</Alert>
        )}
        <Button
          type="button"
          fullWidth
          variant="contained"
          fbColor="primary"
          className={classes.submit}
          onClick={submit}
          disabled={!ready}
        >
          {!loading && "Sign Up"}
          {loading && <CircularProgress color="inherit" className="w-6 h-6" />}
        </Button>
        <Grid container>
          <Grid item className="text-center w-full">
            <AdapterLink to="/login" variant="body2" className="text-gray-3">
              Sign In Instead
            </AdapterLink>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Register;
